/* global ym */
import { Card, CardContent, Typography, Link, CardCover } from "@mui/joy";

import { useContext } from "react";
import { LayoutContext } from "../../context";
import { APIGetShopInfo } from "../../api";



export default function ItemCompany({ item }) {
    const { user, language, modalProductOpen, setModalProductOpen, loadLang, setLoadLang } = useContext(LayoutContext);
    const { modalCompany, setModalCompany, setModalCompanyOpen } = useContext(LayoutContext);

    function toCompany() {
        ym(98288810,'reachGoal','toCompany', {
            name: user?.name, 
            lang: user?.lang, 
            user_id: user?.id
        });
        
        setLoadLang(true);
        APIGetShopInfo(item?.id, true, language).then((data) => {
            if (data) {
                setModalProductOpen(false);
                console.log('SHOP', data)
                setModalCompany(data);
                setModalCompanyOpen(true);
            }
            setLoadLang(false);
        });
    }

    return (
        <Link onClick={() => toCompany()} style={{ width: '100%', textDecoration: 'none' }}>
            <Card 
                variant="plain"
                sx={{ 
                    width: '100%',
                    height: 100,
                    transition: 'transform .3s ease-in-out',
                    '&:hover': { 
                        cursor: 'pointer',
                        transform: 'scale(1.03)', 
                        transition: 'transform .3s ease-in-out',
                        borderColor: 'neutral.outlinedHoverBorder' 
                    }
                }}
            >
                <CardCover>
                    { item?.photos?.length > 0 ?
                        <img
                            src={item?.photos[0]?.photo}
                            loading="lazy"
                            alt=""
                        />
                        :
                        <img
                            src={'/default.png'}
                            loading="lazy"
                            alt=""
                            style={{ objectFit: 'cover' }}
                        />
                    }
                    
                </CardCover>
                <CardCover
                    sx={{ background: 'rgba(0,0,0,0.4)'}}
                />
                <CardContent sx={{ justifyContent: 'flex-end' }}>
                    <Typography
                        level="title-md"
                        textColor="#fff"
                        sx={{ fontWeight: 'lg',lineHeight: 1 }}
                    >
                        {item?.store_name}
                    </Typography>
                </CardContent>
            </Card>
        </Link>
    )
}