

export const LINK_TRANSLATE = "http://localhost:5000";


export const textStock = {
  lang_load: 'Loading a new language...'
}


// -=-=-=- Шаблоны текстов -=-=-=-
const texts = {
    ru: {
      search: 'Поиск',
      searchLook: 'Найти',
      category: 'Категория',
      categories: 'Категории',
      cafes_and_restaurants: 'Кафе и рестораны',
      products: 'Товары',
      account: 'Профиль',
      name: 'Имя',
      ivan: 'Иван',
      phone: 'Номер телефона',
      date_birthday: 'Дата рождения',
      language: 'Язык',
      save: 'Сохранить',
      price: 'Стоимость',
      design: 'Design',
      summary: 'Итог',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'Корзина',
      to_basket: 'В корзину',
      conclusion: 'Итог',
      address: 'Адрес',
      address_delivery: 'Адрес доставки',
      select_point_map: 'Выберите точку на карте',
      comment_order: 'Коментарий к заказу',
      no_comment: 'Без комментариев',
      user_agreement: 'Пользовательское соглашение',
      processing_personal_data: 'Я даю согласие на обработку моих персональных данных в соответствии с',
      processing_personal_link: 'условиями обработки данных',
      read: 'Читать',
      order: 'Заказ',
      submit: 'Заказать',
      developer: 'Разработчик',
      order_development: 'Заказать разработку',
      go_to: 'Перейти',
      map_view: "Вид карты",
      scheme: "Схема",
      satellite: "Спутник",
      hybrid: "Гибрид",
      orders: 'Заказы',
      processing: 'Подтверждение заказа',
      all: 'Все',
      delivered: 'Доставляется',
      delivery: 'Доставка',
      loading: 'Загрузка',
      list_none: 'Тут ничего нет',
      name_none: 'Вы не ввели имя',
      delivered_none: 'К сожалению пока нет свободных курьеров',
      basket_null: "Корзина пустая",
      deliver_here: "Доставить сюда",
      specify_delivery_point: "Укажите точку доставки",
      point_not_delivery_zone: "Точка не в зоне доставки",
      personal_data: "Согласие на обработку персональных данных",
      you_not_auth: "Вы не авторизованы",
      cannot_add_products: "Нельзя добавлять товары из третьего магазина",
      currently_working: "Сейчас работает",
      now_closed: "Сейчас закрыто",
      block_account: "К сожалению ваш аккаунт заблокирован",
      they_trust_us: "Нам доверяют",
      number_orders: "Количество заказов",
      to_24_on_7: "Круглосуточно",
      payment_in_cash: "*Оплата наличными курьеру при получении заказа",
      
      shop_close: "Простите, данный поставщик временно не принимает заказы",
      remove_basket_item_close: "Пожалуйста, удалите или замените товары, которые временно не доступны для заказа",
      shop_order_close: "Магазин в данный момент закрыт. Магазин: ",
      product_not_found: "Товар не найден. ID товара: ",

      not_location_services: "На устройстве службы геолокации недоступны",
      access_location_denied: "Доступ к местоположению запрещен. Необходимо предоставить"
    },
    en: {
      search: 'Search',
      searchLook: 'Find',
      category: 'Category',
      categories: 'Categories',
      cafes_and_restaurants: 'Cafes and restaurants',
      products: 'Goods',
      account: 'Profile',
      name: 'Name',
      ivan: 'Ivan',
      phone: 'Phone number',
      date_birthday: 'Date Birthday',
      language: 'Language',
      save: 'Save',
      price: 'Price',
      design: 'Design',
      summary: 'Summary',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'Basket',
      to_basket: 'Add to basket',
      conclusion: 'Conclusion',
      address: 'Address',
      address_delivery: 'Address delivery',
      select_point_map: 'Select a point on the map',
      comment_order: 'Comment to order',
      no_comment: 'No comment',
      user_agreement: 'User Agreement',
      processing_personal_data: 'I agree to the processing of my personal data in accordance with',
      processing_personal_link: 'terms of data processing',
      read: 'Read',
      order: 'Order',
      submit: 'Place an order',
      developer: 'Developer',
      order_development: 'Order development',
      go_to: 'Go to',
      map_view: "Map view",
      scheme: "Scheme",
      satellite: "Satellite",
      hybrid: "Hybrid",
      orders: 'Orders',
      processing: 'Order confirmation',
      all: 'All',
      delivered: 'Delivered',
      delivery: 'Delivery',
      loading: 'Loading',
      list_none: 'There is nothing here',
      name_none: 'You did not enter a name',
      delivered_none: 'Unfortunately, there are no free couriers at the moment.',
      basket_null: "The basket is empty",
      deliver_here: "Deliver here",
      specify_delivery_point: "Specify the delivery point",
      point_not_delivery_zone: "The point is not in the delivery zone",
      personal_data: "Consent to the processing of personal data",
      you_not_auth: "You are not logged in",
      cannot_add_products: "You cannot add products from a third store",
      currently_working: "Currently working",
      now_closed: "Now closed",
      block_account: "Unfortunately your account has been blocked",
      they_trust_us: "They trust us",
      number_orders: "Number of orders",
      to_24_on_7: "24/7",
      payment_in_cash: "*Payment in cash to the courier upon receipt of the order",
      
      shop_close: "Sorry, this supplier is temporarily not accepting orders",
      remove_basket_item_close: "Please remove or replace items that are temporarily unavailable for order",
      shop_order_close: "The shop is currently closed. Shop:",
      product_not_found: "Product not found. Product ID: ",

      not_location_services: "Location services are not available on the device",
      access_location_denied: "Access to location denied. Must be provided"
    },
    si: {
      search: 'සොයන්න',
      searchLook: 'සොයන්න',
      category: 'ප්රවර්ගය',
      categories: 'වර්ග',
      cafes_and_restaurants: 'කැෆේ සහ අවන්හල්',
      products: 'භාණ්ඩ',
      account: 'පැතිකඩ',
      name: 'නම',
      ivan: 'අයිවන්',
      phone: 'දුරකථන අංකය',
      date_birthday: 'දිනය උපන් දිනය',
      language: 'භාෂාව',
      save: 'සුරකින්න',
      price: 'මිල',
      design: 'Design',
      summary: 'සාරාංශය',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'කූඩය',
      to_basket: 'බැස්කට්ට',
      conclusion: 'පහළ රේඛාව',
      address: 'ලිපිනය',
      address_delivery: 'ලිපින බෙදාහැරීම',
      select_point_map: 'සිතියමෙන් ලක්ෂ්‍යයක් තෝරන්න',
      comment_order: 'ඇණවුම් කිරීමට අදහස් දක්වන්න',
      no_comment: 'ප්රතිචාරයක් නැත',
      user_agreement: 'පරිශීලක ගිවිසුම',
      processing_personal_data: 'අනුව මගේ පුද්ගලික දත්ත සැකසීමට මම එකඟ වෙමි',
      processing_personal_link: 'දත්ත සැකසුම් කොන්දේසි',
      read: 'කියවන්න',
      order: 'ඇණවුම් කරන්න',
      submit: 'ඇනවුමක් කරන්න',
      developer: 'සංවර්ධකයා',
      order_development: 'ඇණවුම් සංවර්ධනය',
      go_to: 'වෙත යන්න',
      map_view: "සිතියම් වර්ගය",
      scheme: "යෝජනා ක්රමය",
      satellite: "චන්ද්රිකාව",
      hybrid: "දෙමුහුන්",
      orders: 'නියෝග',
      processing: 'ඇණවුම තහවුරු කිරීම',
      all: 'සියල්ල',
      delivered: 'භාර දුන්නා',
      delivery: 'භාරදීම',
      loading: 'පැටවීම',
      list_none: 'මෙතන කිසිවක් නැහැ',
      name_none: 'ඔබ නමක් ඇතුලත් කර නැත',
      delivered_none: 'අවාසනාවකට තවමත් කුරියර් නොමැත',
      basket_null: "කරත්තය හිස්ය",
      deliver_here: "මෙහි භාර දෙන්න",
      specify_delivery_point: "බෙදා හැරීමේ ස්ථානය සඳහන් කරන්න",
      point_not_delivery_zone: "ලක්ෂ්යය බෙදාහැරීමේ කලාපයේ නොවේ",
      personal_data: "පුද්ගලික දත්ත සැකසීමට කැමැත්ත",
      you_not_auth: "ඔබට අවසර නැත",
      cannot_add_products: "ඔබට තෙවන වෙළඳසැලකින් නිෂ්පාදන එකතු කළ නොහැක",
      currently_working: "දැනට වැඩ",
      now_closed: "දැන් වහලා",
      block_account: "අවාසනාවකට ඔබගේ ගිණුම අවහිර කර ඇත",
      they_trust_us: "ඔවුන් අපිව විශ්වාස කරනවා",
      number_orders: "ඇණවුම් ගණන",
      to_24_on_7: "24/7",
      payment_in_cash: "*ඇණවුම ලැබුණු පසු කුරියර් වෙත මුදල් ගෙවීම",

      shop_close: "සමාවන්න, මෙම සැපයුම්කරු තාවකාලිකව ඇණවුම් භාර නොගනී",
      remove_basket_item_close: "කරුණාකර ඇණවුම සඳහා තාවකාලිකව නොමැති අයිතම ඉවත් කරන්න හෝ ප්‍රතිස්ථාපනය කරන්න",
      shop_order_close: "දැනට ගබඩාව වසා ඇත. සාප්පු:",
      product_not_found: "නිෂ්පාදනය හමු නොවීය. නිෂ්පාදන ID: ",

      not_location_services: "උපාංගයේ ස්ථාන සේවා නොමැත",
      access_location_denied: "ස්ථාන ප්‍රවේශය ප්‍රතික්ෂේප විය. සැපයිය යුතුය"
    },

    // basket: {
    //   contact: 'Contact Us',
    //   rights: 'All rights reserved.',
    // },
    // buttonLabels: {
    //   submit: 'Submit',
    //   cancel: 'Cancel',
    //   save: 'Save Changes',
    // },
    // errors: {
    //   requiredField: 'This field is required.',
    //   invalidEmail: 'Please enter a valid email address.',
    // },
};
  
export default texts;