import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, GeoJSON, Popup, Tooltip } from 'react-leaflet';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Импорт изображения маркера
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';
// import { GeolocationControl, Map, Placemark, RulerControl, TypeSelector, YMaps, ZoomControl } from '@pbe/react-yandex-maps';
import { Box, Stack, Tab, tabClasses, TabList, Tabs, Typography, useTheme } from '@mui/joy';
import { LayoutContext } from '../../context';
import geojsonData from '../../deliveryZone.json';



// Компонент для фокусировки карты на новой точке при нажатии
const MapFocus = ({ position }) => {
    const map = useMap();
    useEffect(() => {
        if (position?.length != 0) {
            map?.setView(position, 16); // Устанавливаем центр карты и масштаб
        }
    }, [position, map]);
    return null;
};


export default function MapBlock ({ position, setPosition, isInsidePositionZoneDelivery }) {
    // const [position, setPosition] = useState([6.1393, 80.1058]); // Координаты Шри-Ланки, Коломбо
    // const [address, setAddress] = useState("");

    // const theme = useTheme();
    const { textTranslate } = useContext(LayoutContext);
    const [ satelliteView, setSatelliteView ] = useState(0);

    // Стиль для зоны
    // const zoneStyle = {
    //     color: 'blue',
    //     fillColor: 'lightblue',
    //     fillOpacity: 0.5,
    //     weight: 2,
    // };


    // Настройка иконки маркера вручную
    const customIcon = new L.Icon({
        iconUrl: markerIconPng,
        shadowUrl: markerShadowPng,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    // Компонент для обработки кликов по карте
    function LocationMarker() {
        useMapEvents({
        click: async (e) => {
            const { lat, lng } = e.latlng;
            setPosition([lat, lng]);

            // // Выполнить обратное геокодирование для получения адреса
            // try {
            // const response = await axios.get(
            //     `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
            // );
            // if (response.data && response.data.display_name) {
            //     setAddress(response.data.display_name);
            // }
            // } catch (error) {
            // console.error("Ошибка при получении адреса:", error);
            // }
        },
        });

        return position?.length == 0 ? null : (
            <Marker position={position} icon={customIcon}>
                <Tooltip permanent>
                    {textTranslate.deliver_here}
                </Tooltip>
            </Marker>
        );
    }


    return (
        <Stack spacing={1}>
            <Tabs
                aria-label="tabs"
                defaultValue={satelliteView}
                sx={{ width: '100%', bgcolor: 'transparent' }}
                onChange={(e, n) => setSatelliteView(n)}
            >
                <TabList
                    disableUnderline
                    sx={{
                        width: '100%',
                        p: 0.5,
                        gap: 0.5,
                        borderRadius: 'xl',
                        bgcolor: 'background.level1',
                        [`& .${tabClasses.root}[aria-selected="true"]`]: {
                            boxShadow: 'sm',
                            bgcolor: 'background.surface',
                        },
                    }}
                >   
                    <Tab disableIndicator value={0} sx={{
                        width: '100%'
                    }}>
                        {textTranslate.scheme}
                    </Tab>
                    <Tab disableIndicator value={1} sx={{
                        width: '100%'
                    }}>
                        {textTranslate.satellite}
                    </Tab>
                    <Tab disableIndicator value={2} sx={{
                        width: '100%'
                    }}>
                        {textTranslate.hybrid}
                    </Tab>
                </TabList>
            </Tabs>

            <MapContainer
                center={ position?.length == 0 ? [6.1393, 80.1058] : position}
                zoom={15}
                style={{ height: '200px', width: '100%', borderRadius: '12px' }}
            >
                { satelliteView == 0 ?
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        // attribution='&copy; OpenStreetMap contributors'
                    />
                    :
                    satelliteView == 1 ?
                    <TileLayer
                        url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" // Спутниковые тайлы
                        attribution='&copy; Google'
                    />
                    :
                    <TileLayer
                        url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" // Гибридные тайлы
                        attribution='&copy; Google'
                    />
                }
                {position && <LocationMarker />}
                {position && <MapFocus position={position} />}
                <GeoJSON data={geojsonData} style={{ color: isInsidePositionZoneDelivery ? 'green' : 'red', fillOpacity: 0.2 }} />
            </MapContainer>
            {!isInsidePositionZoneDelivery && (
                <Typography color="danger" sx={{ fontWeight: 'md' }}>
                    {textTranslate.point_not_delivery_zone}
                </Typography>
                )
            }
        </Stack>
    );
}




// export default function MapBlock ({ position, setPosition }) {
//     // Изначальные координаты центра карты
//     // const [position, setPosition] = useState([58.0105, 56.2502]);
//     const [placemarkCoords, setPlacemarkCoords] = useState(undefined); // Сначала метка не отображается

//     // Обработчик клика по карте
//     const onMapClick = (e) => {
//         const newCoords = e.get('coords');
//         setPlacemarkCoords(newCoords); // Устанавливаем координаты метки в точку клика
//         console.log('Выбранные координаты:', newCoords); // Можно сохранить или отправить на сервер
//     };

//     return (
//         <Box sx={{ height: 200, width: '100%', borderRadius: '12px', overflow: 'hidden' }}>
//             <YMaps query={{ apikey: '924086eb-390b-4b7e-90c8-319d6514f70b' }}>
//                 <Map
//                     width="100%"
//                     height="100%"
//                     defaultState={{ center: position, zoom: 17, type: 'yandex#satellite' }}
//                     onClick={onMapClick} // Добавляем обработчик клика по карте
//                 >
//                     <GeolocationControl options={{ float: "left" }} />
//                     <RulerControl options={{ float: "right" }} />
//                     <ZoomControl options={{ float: "right" }} />
//                     <TypeSelector options={{ float: "right" }} />
//                     {placemarkCoords && (
//                         <Placemark
//                             geometry={placemarkCoords}
//                             options={{ draggable: true }} // Разрешаем перетаскивание метки
//                             onDragEnd={(e) => {
//                                 const newCoords = e.get('target').geometry.getCoordinates();
//                                 setPlacemarkCoords(newCoords); // Обновляем координаты при перетаскивании
//                             }}
//                         />
//                     )}
//                 </Map>
//             </YMaps>
//         </Box>
//     );
// }