import { Box, Button, Container, Grid, Link, Stack, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../context";
import { useContext, useEffect, useState } from "react";
import { APIGetProductsCategory, APIGetProductsGroup, APISearch, APITypeGroupsByCategory } from "../api";


export default function SearchPage() {
    const { user, textTranslate, language } = useContext(LayoutContext);

    // Получаем значение 'slugGroup' из URL
    const [ searchParams, setSearchParams ] = useSearchParams();
    const q = searchParams.get('q');

    const [ products, setProducts ] = useState([]);
    const [ loadingProducts, setLoadingProducts ] = useState(false);


    useEffect(() => {
        setLoadingProducts(true);
        APISearch(q, language).then((data) => {
            if (data) {
                console.log('Search Products', data)
                setProducts(data);
                setLoadingProducts(false);
            }
        });
    }, [q, language]);


    return (
        <Box>
            <Container maxWidth="sm" sx={{ my: 1, mb: 4 }}>
                <Typography level="h2" mb={2}>
                    {textTranslate.search}
                </Typography>
                { !loadingProducts ?
                    products?.length >= 1 ?
                        <Grid
                            container 
                            spacing={{ xs: 2 }}
                            columns={{ xs: 2, sm: 8, md: 10 }}
                            sx={{ flexGrow: 1 }}
                        >
                            {products?.map((product) => (
                                <Grid item xs={2} sm={4} md={5} key={product.id}>
                                    <ItemProduct orientation={'horizontal'} item={product} />
                                </Grid>
                            ))}
                        </Grid>
                        :
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <img src='/yH.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                            <Typography level="h4" color="primary" sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                                {textTranslate.list_none}
                            </Typography>
                        </Stack>
                    :
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <img src='/shark_load.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                        <Typography sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                            {textTranslate.loading} ...
                        </Typography>
                    </Stack>
                    
                }
            </Container>
        </Box>
    )
}