import { Box, Button, Card, CardContent, CircularProgress, IconButton, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import { API_URL } from "../../api";

export default function BasketItem({ orientation = 'horizontal', item = {} }) {
    const [ count, setCount ] = useState(item.count);
    const { menu, setMenu } = useContext(LayoutContext);
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);

    const { textTranslate } = useContext(LayoutContext);

    // Удаление товар в корзине
    function deleteBask() {
        const rerer = removeBasket(0, item?.product);
        console.log(basket);        
        // setModalProduct({ ...modalProduct, count: modalProduct?.count - 1 });
    }
    // Уменьшить товар в корзине
    function minusBask() {
        const rerer = removeBasket(item?.count - 1, item?.product);
        console.log(basket);        
        // setModalProduct({ ...modalProduct, count: modalProduct?.count - 1 });
    }
    // Увеличить товар в корзине
    function plusBask() {
        const rerer = addBasket(item?.count + 1, item?.setCount, item?.product);
        console.log(basket);
        // setModalProduct({ ...modalProduct, count: modalProduct?.count + 1 });
    }

    return (
        <Card 
            variant="plain"
            size="sm"
            orientation={"horizontal"}
            sx={{
                p: 0,
                width: "100%",
                backgroundColor: 'white',
                boxShadow: 'none',
                transition: '.3s ease-in-out',
                transition: 'transform .3s ease-in-out',
                '&:hover': { 
                    transform: 'scale(1.03)', 
                    transition: 'transform .3s ease-in-out',
                    borderColor: 'neutral.outlinedHoverBorder' 
                }
            }}
        >
            <CardContent sx={{ p: 0, height: "100%" }}>
                <Stack
                    direction={ orientation == 'horizontal' ? 'row' : 'column' }
                    spacing={0}
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 'xl',
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: "stretch",
                    }}
                >
                    <Box sx={{
                        minWidth: 130,
                        minHeight: 120,
                        maxWidth: {
                            xs: orientation == 'horizontal' ? "30vw" : 'auto'
                        },
                        borderRadius: 'xl',
                        overflow: "hidden",
                        p: 0
                    }}>
                        <img
                            src={ item?.product?.photos_product?.length >= 1 ? item?.product?.photos_product[0]?.photo  : '/default.png' } 
                            
                            loading="lazy"
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            width: orientation == 'horizontal' ? "100%" : "auto",
                            p: 1.5,
                            height: 'auto',
                            justifyContent: "space-between",
                            alignItems: "stretch",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            sx={{
                                height: '100%',
                                justifyContent: "flex-start",
                                alignItems: "stretch",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={0}
                                sx={{
                                    // height: '100%',
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography 
                                    level="title-md" 
                                    lineHeight={1.1} 
                                    sx={{
                                        height: "100%",
                                        textAlign: 'left',
                                        display: "-webkit-box",
                                        // wordBreak: "break-all",
                                        WebkitLineClamp: 2.5,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        // maxHeight: "2.3em",
                                        WebkitBoxOrient: "vertical",
                                        fontSize: 'md',
                                        lineHeight: "1em",
                                        // maxWidth: 300,
                                        wordWrap: "break-word", /* Перенос слов, если длинные */
                                        "& p": {
                                            m: 0
                                        }
                                    }}>
                                    <div dangerouslySetInnerHTML={{ __html: item?.product?.name }} />
                                </Typography>
                                <IconButton variant="soft" size="sm" color="danger" onClick={deleteBask}>
                                    <DeleteOutlineRoundedIcon/>
                                </IconButton>
                            </Stack>
                            {/* <Box sx={{ width: '100%', position: 'relative', color: "neutral.300", mt: 0.4, textAlign: 'left' }}>
                                <div dangerouslySetInnerHTML={{ __html: item?.product?.description }} />
                            </Box> */}
                            {/* <Typography level="body-sm" lineHeight={1.1} sx={{ color: "neutral.300", mt: 0.4, textAlign: 'left' }}>
                                <Box sx={{ width: '100%', position: 'relative' }}>
                                    <div dangerouslySetInnerHTML={{ __html: item?.product?.description }} />
                                </Box>
                            </Typography> */}
                        </Stack>

                        <Stack
                            direction={ orientation == 'horizontal' ? 'row' : 'column' }
                            spacing={1}
                            sx={{
                                // height: '100%',
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography level="title-lg" color="primary" sx={{ textAlign: 'left', width: "100%", height: 'auto', verticalAlign: '' }}>
                                {Math.ceil(item?.product?.price)} Rs
                            </Typography>
                            
                            <Stack
                                direction="row"
                                spacing={0}
                                sx={{
                                    width: '100%',
                                    justifyContent: { xs: 'center', sm: 'flex-end' },
                                    alignItems: "stretch",
                                }}
                            >
                                <IconButton variant="soft" color="primary" size="sm" onClick={() => minusBask()} sx={{ borderEndEndRadius: '0', borderTopRightRadius: '0', width: orientation == 'horizontal' ? "auto" : "100%" }}>
                                    <RemoveRoundedIcon />
                                </IconButton>
                                <Sheet variant="soft" color="primary" size="sm"
                                    sx={(theme) => ({
                                        py: 0,
                                        fontSize: 'md',
                                        px: 1.5,
                                        textAlign: "center",
                                        height: 'auto',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: orientation == 'horizontal' ? "auto" : "100%",
                                        // backgroundColor: "primary.800",
                                        borderRadius: '0',
                                        [theme.breakpoints.down('sm')]: {
                                            // width: 'auto'
                                        }
                                    })}
                                >
                                    {item?.count}
                                </Sheet>
                                <IconButton variant="soft" color="primary" size="sm" onClick={() => plusBask()} sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', width: orientation == 'horizontal' ? "auto" : "100%" }}>
                                    <AddRoundedIcon />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                { !item?.product?.shop?.status_work && (
                    <Box sx={{ bgcolor: "danger.200", px: 1, m: 1.2, mt: 1, borderRadius: 'lg', height: 40, display: 'flex', alignItems: "center" }}>
                        <Typography level="title-sm" sx={{ color: "danger.500" }}>
                            {textTranslate?.shop_close}
                        </Typography>
                    </Box>
                )}
                
            </CardContent>
        </Card>
    )
}