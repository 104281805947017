import { Avatar, Badge, Box, Button, Container, Divider, FormControl, IconButton, Input, Link as MuiLink, Stack, Typography } from "@mui/joy";
import SearchHeader from "../searchHeader/SearchHeader";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../context";

import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import { Link } from "react-router-dom";



export default function Header({ pathBag }) {
    const { setModalAccountOpen } = useContext(LayoutContext);
    const { setModalBasketOpen } = useContext(LayoutContext);
    const { basket, textTranslate, webApp, user } = useContext(LayoutContext);

    const [ countBasket, setCountBasket ] = useState(undefined);
    useEffect(() => {
        setCountBasket(basket?.length)
    }, [basket])

    return (
        <header>
            <Container maxWidth="sm" sx={{ py: 2 }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                        alignItems: "stretch"
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Link to="/">
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    width={40}
                                    src="/logo.png"
                                    loading="lazy"
                                    alt=""
                                />
                                <img
                                    width={80}
                                    src="/text_logo.png"
                                    loading="lazy"
                                    alt=""
                                />
                                {/* <Typography level="h3" fontWeight="bold" fontFamily={'NordiquePro'} sx={{ pb: 1 }}>
                                    biteride
                                </Typography> */}
                            </Stack>
                        </Link>
                        <Stack
                            direction="row"
                            spacing={1.5}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {/* <Badge badgeContent={1} size="sm" color="success" variant="solid">
                                <IconButton size="md" onClick={() => setModalOrders(true)}>
                                    <LibraryAddCheckRoundedIcon />
                                </IconButton>
                            </Badge> */}
                            <Badge badgeContent={countBasket} size="sm" variant="soft">
                                <IconButton variant="plain" size="md" onClick={() => countBasket ? setModalBasketOpen(true) : webApp?.showAlert(textTranslate.basket_null)}>
                                    <ShoppingCartRoundedIcon sx={{ color: "neutral.700" }} />
                                </IconButton>
                            </Badge>
                            <Avatar 
                                alt={user?.name || user?.username} 
                                // src="/smile.png" 
                                src={webApp?.initDataUnsafe?.user?.photo_url} 
                                size="md" 
                                onClick={() => setModalAccountOpen(true)}
                                sx={{
                                    cursor: 'pointer',
                                    "& img": {
                                        // height: '25px',
                                        // width: '25px'
                                    }
                                }}/>
                        </Stack>
                    </Box>

                    <SearchHeader pathBag={pathBag}/>
                </Stack>
            </Container>
            <Divider />

            { user?.orders_count && user?.users_count && (
                <Box sx={{ bgcolor: 'primary.100' }}>
                    <Container maxWidth="sm">
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                px: 1,
                                py: 0.5,
                                justifyContent: "space-evenly",
                                alignItems: "stretch",
                            }}
                        >
                            <Typography level="body-xs" sx={{ lineHeight: 1 }} endDecorator={<SentimentSatisfiedAltRoundedIcon color="primary"/>}>
                                {textTranslate?.they_trust_us}:
                                <Typography sx={{ ml: 0.3 }} fontWeight={'xl'}>
                                    {' '}{user?.users_count}
                                </Typography>
                            </Typography>
                            <Typography level="body-xs" sx={{ lineHeight: 1 }} endDecorator={<DeliveryDiningRoundedIcon color="primary"/>}>
                                {textTranslate?.number_orders}:
                                <Typography sx={{ ml: 0.3 }} fontWeight={'xl'}>
                                    {' '}{user?.orders_count}
                                </Typography>
                            </Typography>
                        </Stack>
                    </Container>
                </Box>
            )}      
            
            
        </header>
    )
}