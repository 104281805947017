import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { Card, CardCover, Container } from '@mui/joy';
import { PhotoProvider, PhotoView } from 'react-photo-view';

export default function SliderPhoto({ photos }) {

    console.log("SliderPhoto", photos);
    return (
        <Container maxWidth="sm" sx={{ my: 3, px: 1 }}>
            <PhotoProvider>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    stretch: 10,
                    slideShadows: false,
                }}
                autoplay={{
                    delay: 5000
                }}
                pagination={{
                    dynamicBullets: true,
                }}
                modules={[ EffectCoverflow, Pagination, Autoplay ]}
                className="SwiperCompanyPhoto"
                style={{ margin: '20px 0', width: '100%', borderRadius: '20px' }}
            >
                {
                    photos?.length > 0 ?
                        photos?.map((item, index) => ( 
                            <SwiperSlide style={{ width: 'auto', borderRadius: '20px' }} key={item.id}>
                                <Card component="li" sx={{ width: '100%', maxWidth: 400, minHeight: 200, flexGrow: 1, borderRadius: '20px' }} variant="plain">
                                    <CardCover
                                        sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                                    />
                                    <CardCover sx={{ borderRadius: '0', display: 'flex' }}>
                                        {/* <PhotoProvider> */}
                                            <PhotoView key={index} src={item?.photo} style={{ zIndex: 999, width: '100%', height: '100%' }}>
                                                <img
                                                    src={item?.photo}
                                                    loading="lazy"
                                                    alt=""
                                                    style={{ objectFit: 'cover', width: '100%', objectFit: 'cover' }}
                                                />
                                            </PhotoView>
                                        {/* </PhotoProvider> */}
                                    </CardCover>
                                </Card>
                            </SwiperSlide>
                        ))
                    :
                    <SwiperSlide style={{ width: 'auto', borderRadius: '20px' }}>
                        <Card component="li" sx={{ width: '100%', maxWidth: 900, minHeight: 200, flexGrow: 1, borderRadius: '20px' }} variant="plain">
                            <CardCover sx={{ borderRadius: '0' }}>
                                <img
                                    src={'/default.png'}
                                    loading="lazy"
                                    alt=""
                                    style={{ objectFit: 'cover' }}
                                />
                            </CardCover>
                            <CardCover
                                sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                            />
                        </Card>
                    </SwiperSlide>
                }
            </Swiper>
            </PhotoProvider>
        </Container>
    )
}