import React, { createContext, useContext, useEffect, useState } from 'react';
import { APIGetBanners, APIGetCategories, APIGetListShops, APIGetRandomTypeGroupProducts } from './api';
import { LayoutContext } from './context';



// Создаем контекст с начальным значением
export const DataContext = createContext();


// Создаем провайдер, который будет оборачивать все компоненты
export const DataProvider = ({ children }) => {
    const { language } = useContext(LayoutContext);


    // -=-=- Подгрузка баннеров -=-=-
    const [ dataMainBanners, setDataMainBanners ] = useState([]);
    function GetBanners() {
        APIGetBanners(language).then((data) => {
            if (data) {
                console.log('Loading BANNERS', data);
                setDataMainBanners(data);
            }
        })
    }
    // useEffect(() => {
    //     GetBanners();
    // }, []);
    useEffect(() => {
        GetBanners();
    }, [language]);
    // -=-=-=-=-=-=-=--=--=-=-=-=-


    // -=-=- Подгрузка списка магазинов -=-=-
    const [ listCompany, setListCompany ] = useState([]);
    function GetListCompany() {
        APIGetListShops(language).then((data) => {
            if (data) {
                console.log('Loading Company Shops', data);
                setListCompany(data);
            }
        })
    }
    // useEffect(() => {
    //     GetListCompany();
    // }, []);
    useEffect(() => {
        GetListCompany();
    }, [language]);
    // -=-=-=-=-=-=-=--=--=-=-=-=-


    // -=-=- Подгрузка категорий -=-=-
    const [ listCategories, setListCategories ] = useState([]);
    function GetListCategories() {
        APIGetCategories(language).then((data) => {
            if (data) {
                console.log('Loading Categories', data);
                setListCategories(data);
            }
        })
    }
    // useEffect(() => {
    //     GetListCategories();
    // }, []);
    useEffect(() => {
        GetListCategories();
    }, [language]);
    // -=-=-=-=-=-=-=--=--=-=-=-=-


    // -=-=- Подгрузка рандомных товаров на главную -=-=-
    const [ mainPageListPodbor, setMainPageListPodbor ] = useState([]);
    function GetMainPageListPodbor() {
        APIGetRandomTypeGroupProducts(4, 5, language).then((data) => {
            if (data) {
                console.log('Loading Main Podbor', data);
                setMainPageListPodbor(data);
            }
        })
    }
    // useEffect(() => {
    //     GetMainPageListPodbor();
    // }, []);
    useEffect(() => {
        GetMainPageListPodbor();
    }, [language]);
    // -=-=-=-=-=-=-=--=--=-=-=-=-


    
    const _values = {
        dataMainBanners,
        listCategories,
        listCompany,
        mainPageListPodbor
    }
  
    return (
      <DataContext.Provider value={_values}>
        {children}
      </DataContext.Provider>
    );
};