import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider, Checkbox, FormHelperText, Link, Textarea, ListItem, List, ListItemDecorator, Sheet, ListItemContent, Switch, ListDivider, ListItemButton, switchClasses, sheetClasses, FormControl, Container } from '@mui/joy';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LayoutContext } from '../../context';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded';
import { Link as LinkRouter, useNavigate } from 'react-router-dom';
import MapBlock from './MapBlock';

import { APICreateOrder, APIGetFreeDelivered, DELIVERY_COMISS } from '../../api';
import { useTheme } from '@emotion/react';

import geojsonData from '../../deliveryZone.json';
import * as turf from '@turf/turf';



export default function ModalOrderCreate() {
    const theme = useTheme();
    const { modalBasket, setModalBasketOpen, setModalCompanyOpen, setModalProductOpen } = useContext(LayoutContext);
    const { modalOrderCreate, setModalOrderCreate, basket, setBasket, user, webApp } = useContext(LayoutContext);

    const navigate = useNavigate()
    const { textTranslate, language } = useContext(LayoutContext);

    // Точка доставки
    // [6.1393, 80.1058] Координаты Шри-Ланки, Коломбо
    const [ position, setPosition ] = useState([]);
    // Проверка точки внутри зоны
    const isInsidePositionZoneDelivery = useMemo(() => {
        // console.log(position);
        if (position?.length == 0) return true;
        for (let i = 0; i < geojsonData?.features?.length; i++) {
            // Проверяем, находится ли точка внутри хотябы одного полигона
            if (turf.booleanPointInPolygon(turf.point([position[1], position[0]]), geojsonData?.features[i])) {
              return true; // Если точка внутри хотя бы одного полигона, возвращаем true
            }
        }
        return false; // Если точка не внутри ни одного полигона, возвращаем false
    }, [position]);

    // Статус загрузки для кнопки
    const [ btnLoading, setBtnLoading ] = useState(false);
    const [ name, setName ] = useState(user?.name);
    const [ comment, setComment ] = useState('');

    useEffect(() => {
        setName(user?.name);
    }, [user]);

    const [ info, setInfo ] = useState('');


    // Подгружаем локацию пользователя
    useEffect(() => {
        if (modalOrderCreate) {
            const localManager = webApp?.LocationManager;
            if (localManager) {
                // Инициализация LocationManager
                localManager?.init(() => {
                    // console.log('LocationManager Инициализация:', localManager?.isInited);

                    // Проверяем доступность геолокации
                    // if (!localManager?.isLocationAvailable) {
                    //     webApp?.showAlert(textTranslate.not_location_services);
                    //     return;
                    // }

                    // Проверяем запрашивались ли ранее разрешения
                    if (localManager?.isAccessRequested && !localManager?.isAccessGranted) {
                        webApp?.showAlert(textTranslate.access_location_denied);
                        // Открываем настройки доступа к геолокации
                        localManager?.openSettings();
                        return;
                    }
                });
            }
        }
    }, [modalOrderCreate]);
    
    // Реакция на кнопку получения геопозиции
    const requestLocation = () => {
        const localManager = webApp?.LocationManager;

        if (localManager) {
            // Инициализация LocationManager
            // localManager?.init(() => {
            //     setInfo('');
            //     var _t = '';
            //     _t += 'LocationManager Инициализация:' + localManager?.isInited
            //     _t += "<br>" + localManager?.isLocationAvailable
            //     _t += "<br>" +  localManager?.isAccessRequested
            //     _t += "<br>" + localManager?.isAccessGranted
            //     setInfo(_t);
            // });
            
            // Проверяем доступность геолокации
            // if (!localManager?.isLocationAvailable) {
            //     webApp?.showAlert(textTranslate.not_location_services);
            //     return;
            // }
            // if (localManager?.isAccessRequested && !localManager?.isAccessGranted) {
            //     // webApp?.showAlert(textTranslate.access_location_denied);
            //     // Открываем настройки доступа к геолокации
            //     localManager?.openSettings();
            //     return;
            // }
            // Запрос геолокации
            localManager?.getLocation((locationData) => {
                if (!locationData) {
                    if (!localManager?.isLocationAvailable) {
                        webApp?.showAlert(textTranslate.not_location_services);
                        return;
                    }
                    webApp?.showAlert(textTranslate.access_location_denied);
                    // Открываем настройки доступа к геолокации
                    localManager?.openSettings();
                } else {
                    // console.log('Полученные данные о местоположении:', locationData);
                    setPosition([locationData?.latitude, locationData?.longitude]);
                }
            });
        }
    };

    // Ссылка на политику конф.
    // function clickUrl() {
    //     var _link = ''
    //     if (language == 'en')
    //         _link = 'https://docs.google.com/document/d/1VgkoLBWKlVb23q6GQmZNm1CmVYA2NJWD/edit?usp=sharing&ouid=107736560966592558568&rtpof=true&sd=true'
    //     if (language == 'ru')
    //         _link = 'https://docs.google.com/document/d/1o9qjEnwFfr_AGRY8rVwO7iTTd0N80slQ/edit?usp=sharing&ouid=107736560966592558568&rtpof=true&sd=true'
    //     if (language == 'si')
    //         _link = 'https://docs.google.com/document/d/1WSY8rkj9dgooxV-6N070ygXuhn0axe6g/edit?usp=sharing&ouid=107736560966592558568&rtpof=true&sd=true'

    //     webApp?.BackButton?.show();
    //     webApp?.BackButton?.onClick(() => {
    //         window.location.href = "https://biteride.pro/";
    //     });
    //     window.location.href = _link;
    // }

    // Ссылка на обработку персонт. данных
    function clickUrl() {
        var _link = ''
        if (language == 'en')
            _link = 'https://docs.google.com/document/d/1VgkoLBWKlVb23q6GQmZNm1CmVYA2NJWD/preview?embedded=true'
        if (language == 'ru')
            _link = 'https://docs.google.com/document/d/1o9qjEnwFfr_AGRY8rVwO7iTTd0N80slQ/preview?embedded=true'
        if (language == 'si')
            _link = 'https://docs.google.com/document/d/1WSY8rkj9dgooxV-6N070ygXuhn0axe6g/preview?embedded=true'


        setModalOrderCreate(false);
        setModalBasketOpen(false);
        setModalCompanyOpen(false);
        setModalProductOpen(false);
        navigate(`/personal_data?urlDoc=${_link}`)
    }

    
    // Ссылка для политики конф.
    // const LinkPrivacy = useMemo(() => {
    //     var _link = ''
    //     if (language == 'en')
    //         _link = 'https://docs.google.com/document/d/1VgkoLBWKlVb23q6GQmZNm1CmVYA2NJWD/edit?usp=sharing&ouid=107736560966592558568&rtpof=true&sd=true'
    //     if (language == 'ru')
    //         _link = 'https://docs.google.com/document/d/1o9qjEnwFfr_AGRY8rVwO7iTTd0N80slQ/edit?usp=sharing&ouid=107736560966592558568&rtpof=true&sd=true'
    //     if (language == 'si')
    //         _link = 'https://docs.google.com/document/d/1WSY8rkj9dgooxV-6N070ygXuhn0axe6g/edit?usp=sharing&ouid=107736560966592558568&rtpof=true&sd=true'

    //     return (
    //         <Link color='primary' href={_link}>
    //             {textTranslate.policy_link}
    //         </Link>
    //     )
    // }, [user, language]);

    // Перейти на главную
    function toHome() {
        setModalOrderCreate(false);
        setModalBasketOpen(false);
    }

    // Функция создания заказа
    async function orderApiCreate(event) {
        event.preventDefault();
        const items = basket?.map((item) => {return {product_id: item.product.id, count: item.count}})

        const currentDateTime = new Date();
        // Также можно получить время с учетом таймзоны
        const timeZoneOffset = currentDateTime.getTimezoneOffset() * 60000; // Разница в миллисекундах
        const localTimeWithOffset = new Date(currentDateTime.getTime() - timeZoneOffset).toISOString();
        
        setBtnLoading(true);
        const _data = {
            "token": user?.token,
            "date_client_create": localTimeWithOffset,
            "address_delivery": {
                "latitude": position[0]?.toFixed(6),
                "longitude": position[1]?.toFixed(6)
            },
            "name": name,
            "comment": comment,
            "items": items
        }

        // (1) Проверяем что точка указана
        if (position?.length == 0) {
            setBtnLoading(false);
            webApp?.showAlert(textTranslate.specify_delivery_point);
            return
        }

        // (2) Проdеряем что точка в зоне доставки
        if (!isInsidePositionZoneDelivery){
            setBtnLoading(false);
            webApp?.showAlert(textTranslate.point_not_delivery_zone);
            return
        }

        // (3) Проверяем что есть свободные курьеры
        const data_delivered = await APIGetFreeDelivered();
        if (data_delivered)
            if (data_delivered?.count == 0){
                setBtnLoading(false);
                webApp?.showAlert(textTranslate.delivered_none);
                return
            }
        
        // Создаем заказ
        APICreateOrder(_data).then((data) => {
            if (data?.status == 'ok') {
                console.log('Create ORDER', data);
                if (data?.order?.id) {
                    webApp?.HapticFeedback?.notificationOccurred('success');
                    const orderDetails = { type: 'create_order', id: data?.id };
                    // Отправляем данные о заказе в бота
                    webApp?.sendData(JSON.stringify(orderDetails)); // Отправляем данные в формате JSON
                    // Закрываем WebApp
                    webApp?.close();
                    
                    setBtnLoading(false);
                    setBasket([]);
                    toHome();
                    return
                }
                return 
            }
            // Если магазин закрыт
            else if (data?.response?.data?.status == 'not_work') {
                setBtnLoading(false);
                webApp?.showAlert(textTranslate?.shop_order_close + data?.response?.data?.shop);
                return
            }
            // Если товар не найден
            else if (data?.response?.data?.status == 'product') {
                setBtnLoading(false);
                webApp?.showAlert(textTranslate?.product_not_found + data?.response?.data?.id);
                return
            }
        });
    }

    const [ totalPrice, setTotalPrice ] = useState(0);
    useEffect(() => {
        var sum = 0;
        basket?.map((item) => (
            sum += item.product.price * item.count
        ))
        setTotalPrice(sum);
        if (basket?.length <= 0) setModalBasketOpen(false);
    }, [basket])
    
    useEffect(() => {
        if (modalBasket | modalOrderCreate) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        return () => document.body.style.overflow = 'auto';
    }, [modalBasket, modalOrderCreate]);
    


    return (
        <AnimatePresence>
            {modalOrderCreate && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 14,
                        }}
                    />
                    <motion.div
                        initial={{ x: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        animate={{ x: 0, opacity: 1 }} // Анимация открытия
                        exit={{ x: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: '#f3f3f3',
                            zIndex: 15,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <Container maxWidth="sm" sx={{ mb: 4, px: 0 }}>
                            <form onSubmit={orderApiCreate}
                                style={{
                                    margin: theme.spacing(2),
                                    marginBottom: theme.spacing(4),
                                }}
                            >
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2 }}
                                    sx={{
                                        mb: 2
                                    }}
                                >
                                    <Typography level='h2' sx={{ lineHeight: 1 }}>
                                        {textTranslate.processing}
                                    </Typography>
                                    <IconButton variant="soft" color="danger" onClick={toHome} sx={{ height: 'fit-content' }}>
                                        <HomeRoundedIcon />
                                    </IconButton>
                                </Stack>
                                <Divider/>
                                <Typography>
                                    {info}
                                </Typography>

                                <Button sx={{ mt: 2, width: 'fit-content' }} startDecorator={<ArrowBackIosRoundedIcon/>} color="primary" variant="soft" onClick={() => setModalOrderCreate(false)}>
                                    {textTranslate.basket}
                                </Button>

                                <Stack
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    spacing={{ xs: 1, sm: 2 }}
                                    direction={"column"}
                                    sx={(theme) => ({
                                        width: 'auto',
                                        p: 1,
                                        my: 1
                                    })}
                                >   
                                    <Stack
                                        direction='column'
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Box sx={{ width: '100%' }}>
                                            <Stack direction={'row'} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                                <Box>
                                                    <Typography sx={{ mb: 0 }} level='title-md' fontWeight={'xl'}>
                                                        {textTranslate.address_delivery}*
                                                    </Typography>
                                                    <Typography sx={{ mb: 0.5 }} level='body-xs' color='primary'>
                                                        {textTranslate.select_point_map}
                                                    </Typography>
                                                </Box>
                                                <IconButton color="primary" sx={{ height: "min-content" }} size="md" variant="soft" onClick={requestLocation}>
                                                    <NearMeRoundedIcon/>
                                                </IconButton>
                                            </Stack>
                                            <MapBlock position={position} setPosition={setPosition} isInsidePositionZoneDelivery={isInsidePositionZoneDelivery}/>
                                            {/* <Input type="text" placeholder="Луначарского, 99" variant="soft" sx={{ mt: 2 }} /> */}
                                            
                                            {/* <List
                                                aria-labelledby="ios-example-demo"
                                                variant='outlined'
                                                sx={(theme) => ({
                                                    mt: 2,
                                                    borderColor: 'background.level1',
                                                    '& ul': {
                                                        '--List-gap': '0px',
                                                        '& > li:first-child > [role="button"]': {
                                                            borderTopRightRadius: 'var(--List-radius)',
                                                            borderTopLeftRadius: 'var(--List-radius)',
                                                        },
                                                        '& > li:last-child > [role="button"]': {
                                                            borderBottomRightRadius: 'var(--List-radius)',
                                                            borderBottomLeftRadius: 'var(--List-radius)',
                                                        },
                                                    },
                                                    '--List-radius': theme.radius.sm,
                                                    '--List-gap': '1rem',
                                                    '--ListDivider-gap': '0px',
                                                    '--ListItem-paddingY': '0.5rem',

                                                    '--joy-palette-neutral-plainHoverBg': 'rgba(0 0 0 / 0.08)',
                                                    '--joy-palette-neutral-plainActiveBg': 'rgba(0 0 0 / 0.12)',
                                                    [theme.getColorSchemeSelector('light')]: {
                                                        '--joy-palette-divider': 'rgba(0 0 0 / 0.08)',
                                                    },
                                                    [theme.getColorSchemeSelector('dark')]: {
                                                        '--joy-palette-neutral-plainHoverBg': 'rgba(255 255 255 / 0.1)',
                                                        '--joy-palette-neutral-plainActiveBg': 'rgba(255 255 255 / 0.16)',
                                                    },
                                                })}
                                            >
                                                <ListItem nested>
                                                    <List
                                                        aria-label="Network"
                                                        sx={{
                                                            [`& .${sheetClasses.root}`]: {
                                                                p: 0.8,
                                                                lineHeight: 0,
                                                                borderRadius: 'sm',
                                                            },
                                                        }}
                                                    >
                                                        <ListItem>
                                                            <ListItemButton>
                                                                <ListItemDecorator>
                                                                <Sheet variant="solid" color="primary">
                                                                    <PinDropRoundedIcon />
                                                                </Sheet>
                                                                </ListItemDecorator>
                                                                <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                    Луначарского, 99
                                                                </ListItemContent>
                                                                <Button
                                                                    color='danger'
                                                                    sx={{ px: 1 }}
                                                                    size='sm' 
                                                                    variant="soft"
                                                                    onClick={() => alert("DEL")}
                                                                >
                                                                    <DeleteOutlineRoundedIcon />
                                                                </Button>
                                                                <ArrowForwardIosRoundedIcon fontSize="xl1" />
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <ListDivider inset="startContent" />
                                                        <ListItem>
                                                            <ListItemButton>
                                                                <ListItemDecorator>
                                                                <Sheet variant="solid" color="primary">
                                                                    <PinDropRoundedIcon />
                                                                </Sheet>
                                                                </ListItemDecorator>
                                                                <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                    Луначарского, 99
                                                                </ListItemContent>
                                                                <Button
                                                                    color='danger'
                                                                    sx={{ px: 1 }}
                                                                    size='sm' 
                                                                    variant="soft"
                                                                    onClick={() => alert("DEL")}
                                                                >
                                                                    <DeleteOutlineRoundedIcon />
                                                                </Button>
                                                                <ArrowForwardIosRoundedIcon fontSize="xl1" />
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <ListDivider inset="startContent" />
                                                        <ListItem>
                                                            <ListItemButton>
                                                                <ListItemDecorator>
                                                                <Sheet variant="solid" color="primary">
                                                                    <PinDropRoundedIcon />
                                                                </Sheet>
                                                                </ListItemDecorator>
                                                                <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                    Луначарского, 99
                                                                </ListItemContent>
                                                                <Button
                                                                    color='danger'
                                                                    sx={{ px: 1 }}
                                                                    size='sm' 
                                                                    variant="soft"
                                                                    onClick={() => alert("DEL")}
                                                                >
                                                                    <DeleteOutlineRoundedIcon />
                                                                </Button>
                                                                <ArrowForwardIosRoundedIcon fontSize="xl1" />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </List>
                                                </ListItem>
                                            </List> */}
                                        </Box>
                                        <Box sx={{ width: '100%' }}>
                                            <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                                {textTranslate.comment_order}
                                            </Typography>
                                            <Textarea minRows={3} variant="soft" sx={{ backgroundColor: "white", boxShadow: "none" }} value={comment} onChange={(e) => setComment(e.target.value)} placeholder={textTranslate.no_comment} />
                                        </Box>

                                        <Box sx={{ width: '100%' }}>
                                            <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                                {textTranslate.name}*
                                            </Typography>
                                            <Input required type="text" sx={{ backgroundColor: "white", boxShadow: "none" }} onChange={(e) => setName(e.target.value)} value={name} placeholder={textTranslate.ivan} variant="soft" />
                                        </Box>
                                        {/* <Box sx={{ width: '100%' }}>
                                            <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                                Номер телефона*
                                            </Typography>
                                            <InputPhone phone={phone} setPhone={setPhone}/>
                                        </Box> */}

                                        <Box sx={{ width: '100%' }}>
                                            <Checkbox
                                                required
                                                label={
                                                <Box>
                                                    <Typography level="body-sm" lineHeight={1}>{textTranslate.processing_personal_data}</Typography>
                                                </Box>
                                                }
                                            />
                                            <FormHelperText sx={{ my: 0 }}>
                                                <Typography level="body-xs">
                                                    {/* {textTranslate.read} {' '} */}
                                                    <Link color='primary' onClick={clickUrl}>
                                                        {textTranslate.processing_personal_link}
                                                    </Link>.
                                                </Typography>
                                            </FormHelperText>
                                        </Box>
                                    </Stack>
                                </Stack>
                                
                                <Box
                                    sx={(theme) => ({
                                        p: 1,
                                        // boxShadow: 'md',
                                        backdropFilter: 'blur(8px)',
                                        backgroundColor: '#ffffff59',
                                        position: 'sticky', bottom: 30,
                                        borderRadius: 'md',
                                    })}
                                >
                                    <Stack
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        spacing={{ xs: 1, sm: 2 }}
                                        direction={"column"}
                                        sx={(theme) => ({
                                            // p: 1,
                                            mb: 1
                                            // boxShadow: 'md',
                                            // backdropFilter: 'blur(10px)',
                                            // backgroundColor: theme.vars.palette.neutral[50],
                                            // position: 'sticky', bottom: 10,
                                            // borderRadius: 'md',
                                        })}
                                    >   
                                        <Stack
                                            direction='row'
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={4}
                                        >
                                            
                                            <Stack
                                                sx={{ height: '100%', width: '100%' }}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="flex-start"
                                                spacing={0.5}
                                            >
                                                <Typography level='h3'>
                                                    {textTranslate.conclusion}:
                                                </Typography>
                                                <Typography fontWeight="xl" level="h3">
                                                    {Math.ceil(totalPrice) + DELIVERY_COMISS} Rs
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Button 
                                        color='primary'
                                        size="lg"
                                        type='submit'
                                        loading={btnLoading}
                                        // onClick={orderApiCreate}
                                        sx={{
                                            height: 40,
                                            fontSize: 'lg',
                                            width: '100%'
                                        }}
                                    >
                                        {textTranslate.submit}
                                    </Button>

                                    <Typography level='body-xs' sx={{ textAlign: 'center' }}>
                                        {textTranslate.payment_in_cash}
                                    </Typography>
                                </Box>
                            </form>
                        </Container>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}