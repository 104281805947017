import { AnimatePresence, motion } from 'framer-motion';
import { Box, Button, Stack, Typography, IconButton, Divider, Container, Grid, useTheme } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';
import BasketItem from './BasketItem';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useMediaQuery } from '@mui/system';
import { DELIVERY_COMISS } from '../../api';


export default function ModalBasket() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('xssm'));
    const isMedium2Screen = useMediaQuery(theme.breakpoints.up('xssm2'));

    const { modalBasketOpen, setModalBasketOpen, basket, webApp } = useContext(LayoutContext);
    const { modalOrderCreate, setModalOrderCreate } = useContext(LayoutContext);
    const { textTranslate } = useContext(LayoutContext);


    useEffect(() => {
        if ((modalBasketOpen || modalOrderCreate) && document.body.style.overflow == 'auto') {
            document.body.style.overflow = 'hidden';
            return
        }
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        // return () => document.body.style.overflow = 'hidden';
    }, [modalBasketOpen, modalOrderCreate]);

    const [ totalPrice, setTotalPrice ] = useState(0);
    useEffect(() => {
        var sum = 0;
        basket?.map((item) => (
            sum += item.product.price * item.count
        ))
        setTotalPrice(sum);
        if (basket?.length <= 0) setModalBasketOpen(false);
    }, [basket])


    // Переход на оформление заказа
    function toCreateOrderStep() {
        const shop_not_work = basket?.filter((item) => {
            return !item?.product?.shop?.status_work
        });
        
        // Првоеряем есть ли товары, магазины которых сейчас в статусе закрыт
        if (shop_not_work?.length > 0) {
            webApp?.showAlert(textTranslate?.remove_basket_item_close);
            return
        }
        setModalOrderCreate(true);
    }
    


    return (
        <AnimatePresence>
            {modalBasketOpen && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 13,
                        }}
                    />
                    <motion.div
                        initial={{ y: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        // animate={{ y: 0, opacity: 1 }} // Анимация открытия
                        animate={modalOrderCreate ? { scale: 0.9, y: 0, borderRadius: '25px' } : { y: 0, opacity: 1 }}
                        exit={{ y: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: '#f3f3f3',
                            zIndex: 14,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <Container maxWidth="sm" sx={{ mb: 4, px: 2 }}>
                        {/* <Box
                            sx={{
                                m: 2,
                                mb: 4
                            }}
                        > */}
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={{ xs: 1, sm: 2 }}
                                sx={{
                                    my: 2
                                }}
                            >
                                <Typography level='h2' sx={{ my: 2 }}>
                                    {textTranslate.basket}
                                </Typography>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2 }}
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    <IconButton variant="soft" color="danger" onClick={() => setModalBasketOpen(false)} sx={{ height: 'fit-content' }}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Divider/>
                        
                                    
                            <Grid
                                container 
                                spacing={{ xs: 1 }}
                                columns={{ xs: 4, xssm2: 8, sm: 8, md: 10 }}
                                sx={{ flexGrow: 1, my: 2 }}
                            >
                            {/* <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={1}
                                direction={{ xs: 'column', sm: 'column' }}
                                sx={{ my: 2 }}
                            > */}
                                { basket.length >= 1 ?
                                    basket?.map((item) => ( 
                                        <Grid item xs={4} xssm2={4} sm={4} md={5} key={item?.product?.id}>
                                            <BasketItem orientation={isMediumScreen ? isMedium2Screen ? 'vertical' : 'horizontal' : 'vertical'} item={item}/>
                                        </Grid>
                                    ))
                                    :
                                    undefined
                                }
                            {/* </Stack> */}
                            </Grid>
                            
                            

                            <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={{ xs: 1, sm: 2 }}
                                direction={"column"}
                                sx={(theme) => ({
                                    p: 2,
                                    mb: 2,
                                    zIndex: 2,
                                    boxShadow: 'md',
                                    backdropFilter: 'blur(10px)',
                                    backgroundColor: theme.vars.palette.primary[1000],
                                    position: 'sticky', bottom: 30,
                                    borderRadius: 'md',
                                })}
                            >   
                                <Stack
                                    direction='column'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <Stack
                                            sx={{ bgcolor: 'transparent', p: 0, height: '100%' }}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={0.5}
                                        >
                                            <Box>
                                                <Typography level='title-lg' sx={{ color: 'neutral.50' }}>
                                                    {textTranslate.delivery}:
                                                </Typography>
                                            </Box>
                                            <Typography fontWeight="xl" level="title-lg" sx={{ color: 'primary.600' }}>
                                                +{DELIVERY_COMISS} Rs
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Stack
                                        sx={{ height: '100%', width: '100%' }}
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        spacing={0.5}
                                    >
                                        <Box>
                                            <Typography level='h3' sx={(theme) => ({ color: theme.vars.palette.neutral[50] })}>
                                                {textTranslate.conclusion}:
                                            </Typography>
                                        </Box>
                                        <Typography fontWeight="xl" level="h3" sx={(theme) => ({ color: theme.vars.palette.primary[800] })}>
                                            {Math.ceil(totalPrice) + DELIVERY_COMISS} Rs
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Button 
                                    sx={{ 
                                        height: 40,
                                        fontSize: 'md', 
                                        width: { xs: '100%', lg: 'auto' }
                                    }}
                                    onClick={toCreateOrderStep}
                                >
                                    {textTranslate.submit}
                                </Button>
                            </Stack>
                        </Container>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}