import React, { useContext } from "react";
import { Box, Container, Stack, Typography, Link } from "@mui/joy";

import LaunchIcon from '@mui/icons-material/Launch';
import { LayoutContext } from "../../context";
import { useNavigate } from "react-router-dom";

export default function Footer() {
    const { textTranslate, user, language } = useContext(LayoutContext);
    const { setModalOrderCreate, setModalBasketOpen, setModalCompanyOpen, setModalProductOpen } = useContext(LayoutContext);
    const navigate = useNavigate();


    // Ссылка на обработку персонт. данных
    function clickUrl() {
        var _link = ''
        if (language == 'en')
            _link = 'https://docs.google.com/document/d/177MdMbhn5Fn_eRe2d7DfPCh91bS8E3Ju/preview?embedded=true'
        if (language == 'ru')
            _link = 'https://docs.google.com/document/d/1rvUBDkID8_fhPQh_BVjbt-q9-jjvm09H/preview?embedded=true'
        if (language == 'si')
            _link = 'https://docs.google.com/document/d/10mjbq21i8XwM_yf2kRsihOXejmk2SH7n/preview?embedded=true'

        setModalOrderCreate(false);
        setModalBasketOpen(false);
        setModalCompanyOpen(false);
        setModalProductOpen(false);
        navigate(`/policy?urlDoc=${_link}`)
    }

    return (
        <Box>
            <Box style={{ backgroundColor: '#1a1a1a', padding: '10px 0' }}>
                <Container sx={{ height: '100%' }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={{ xs: 2, sm: 3 }}
                    >
                        <Typography textColor={'neutral.300'} sx={{ fontSize: { xs: 'sm', sm: 'md'} }}>
                            {textTranslate?.developer}: <Link href={'https://t.me/kiryushka01'} fontWeight={'xl'} color="neutral" textColor={'neutral.100'} endDecorator={<LaunchIcon/>}> @kirill_skryabin</Link>
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={{ xs: 2, sm: 3 }}
                    >
                        <Typography textColor={'neutral.300'} sx={{ fontSize: { xs: 'sm', sm: 'md'} }}>
                            <Link onClick={clickUrl} fontWeight={'xl'} color="neutral" textColor={'neutral.100'}>{textTranslate.user_agreement}</Link>
                        </Typography>
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
}