/* global ym */
import { AspectRatio, Button, Sheet, Card, CardActions, CardContent, CardOverflow, Divider, IconButton, Typography, Stack, Box, ButtonGroup, Link } from "@mui/joy";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { LayoutContext } from "../../context";
import { AnimatePresence } from "framer-motion";
import ModalProduct from "../ModalProduct/ModalProduct";
import { Link as LinkRouter } from "react-router-dom";
import { API_URL, APIGetShopInfo } from "../../api";



export default function ItemProduct({ orientation, item }) {
    const { user, language, modalProductOpen, setModalProductOpen, setModalProduct, textTranslate, setLoadLang } = useContext(LayoutContext);
    const { modalCompany, setModalCompany, setModalCompanyOpen } = useContext(LayoutContext);
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);
    const [ count, setCount ] = useState(0);

    const [ orient, setOrient ] = useState('horizontal');

    useEffect(() => {
        setOrient(orientation);
    }, [orientation]);

    // При Mount, отслеживаем есть ли товар в корзине
    useEffect(() => {
        const bask_id = basket.findIndex((element) => element.product.id === item.id)
        if (bask_id > -1) setCount(basket[bask_id].count);
        else setCount(0);
    }, [basket, item]);


    const handle = () => {
        console.log('fff', item);
        setModalProduct({product: item, count: count, setCount: setCount});
        setModalProductOpen(true);
    };

    function toCompany() {
        ym(98288810,'reachGoal','toCompany', {
            name: user?.name, 
            lang: user?.lang, 
            user_id: user?.id
        });

        setLoadLang(true);
        APIGetShopInfo(item?.shop?.id, true, language).then((data) => {
            if (data) {
                setModalProductOpen(false);
                console.log('SHOP', data)
                setModalCompany(data);
                setModalCompanyOpen(true);
            }
            setLoadLang(false);
        });
    }

    // Уменьшить товар в корзине
    function minusBask() {
        const rerer = removeBasket(count - 1, item);
        console.log(basket);        
        setCount(rerer);
    }
    // Увеличить товар в корзине
    function plusBask() {
        const rerer = addBasket(count + 1, setCount, item);
        console.log(basket);
        setCount(rerer);
    }

    // const translateDish = async () => {
    //     const text = "Hello";
    //     const sourceLang = "en";
    //     const targetLang = "ru";
    //     // Логика вызова API для перевода текста
    //     const response = await fetch(`https://lingva.ml/api/v1/${sourceLang}/${targetLang}/${encodeURIComponent(text)}`);
    //     const data = await response.json();
    //     console.log(data);
    //     console.log(data.translation);
    //     // return data.translatedText;
    // };

    return (
        <Card 
            variant="plain"
            size="sm"
            orientation={"horizontal"}
            sx={{
                p: 0,
                height: "100%",
                backgroundColor: 'white',
                boxShadow: 'none',
                transition: '.3s ease-in-out',
                transition: 'transform .3s ease-in-out',
                '&:hover': { 
                    transform: 'scale(1.03)', 
                    transition: 'transform .3s ease-in-out',
                    borderColor: 'neutral.outlinedHoverBorder' 
                }
            }}
        >
            <CardContent sx={{ p: 0, height: "100%" }}>
                <Stack
                    direction={ orient == 'horizontal' ? "row" : "column" }
                    spacing={0}
                    sx={{
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: "stretch",
                    }}
                >
                    <Box 
                        onClick={() => handle()}
                        sx={{
                            cursor: 'pointer',
                            minWidth: 130,
                            minHeight: 130,
                            height: orient == 'horizontal' ? 'auto' : '150px',
                            maxWidth: {
                                xs: orient == 'horizontal' ? "30vw" : 'auto'
                            },
                            borderRadius: 'xl',
                            overflow: "hidden",
                            p: 0
                        }}
                    >
                        <img
                            src={ item?.photos_product?.length >= 1 ? item?.photos_product[0].photo : '/default.png' }
                            loading="lazy"
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            width: orient == 'horizontal' ? "100%" : "auto",
                            p: 1.5,
                            height: orient == 'horizontal' ? "auto" : "45%",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={0.5}
                            sx={{
                                // height: '100%',
                                justifyContent: "flex-start",
                                alignItems: "stretch",
                            }}
                        >
                            <Typography 
                                // level={"title-lg"}
                                lineHeight={1.1} 
                                sx={{ 
                                    pb: 1,
                                    fontWeight: 'md', 
                                    cursor: 'pointer', 
                                    textAlign: 'left',
                                    display: "-webkit-box",
                                    // wordBreak: "break-all",
                                    WebkitLineClamp: 2,
                                    maxHeight: "2em",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontSize: 'md',
                                    lineHeight: "1.2em",
                                    maxWidth: 300,
                                    wordWrap: "break-word", /* Перенос слов, если длинные */
                                    "& p": {
                                        m: 0
                                    }
                                }} 
                                onClick={() => handle()}
                            >
                                <div dangerouslySetInnerHTML={{ __html: item?.name }} />
                            </Typography>
                            {/* <Typography 
                                level="body-sm"
                                sx={{ 
                                    color: "neutral.300", 
                                    textAlign: 'left',
                                    // width: 300,
                                    height: '2.4em',
                                    lineHeight: '1.2em',
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    webkitLineClamp: 2,
                                    webkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'normal',
                                }}
                            >
                                {item?.description}
                            </Typography> */}
                        </Stack>
                        <Stack
                            direction={"column"}
                            spacing={.5}
                            sx={{
                                mt: 1,
                                // px: 1.5,
                                // height: '100%',
                                justifyContent: "space-between",
                                alignItems: "stretch",
                            }}
                        >
                            <Link endDecorator={<LaunchRoundedIcon fontSize='md' sx={{ color: 'primary.500' }}/>} sx={{ width: "fit-content", wordBreak: "break-all", textAlign: 'left', lineHeight: 1.2 }} underline="always" color="primary" level="body-sm" onClick={() => toCompany() }>
                                {item?.shop?.store_name}
                            </Link>
                            <Stack
                                direction={ orient == 'horizontal' ? "row" : "column" }
                                spacing={.5}
                                sx={{
                                    pt: 1,
                                    // px: 1.5,
                                    // height: '100%',
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography level="title-lg" color="primary" sx={{ textAlign: 'left', width: "100%", height: 'auto', verticalAlign: '' }}>
                                    {Math.ceil(item?.price)} Rs
                                </Typography>
                            
                                <Box 
                                    sx={{ 
                                        width: "100%",
                                        opacity: item?.shop?.status_work ? 1 : 0.5, // Бледный, если магазин закрыт
                                        pointerEvents: item?.shop?.status_work ? "auto" : "none", // Отключает клики, если магазин закрыт
                                    }}
                                >
                                    {
                                        count <= 0 ?
                                            <Button 
                                                size="sm"
                                                variant="soft"
                                                color={item?.shop?.status_work ? "primary" : "danger"}
                                                sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: '100%', lineHeight: 1 }}
                                                onClick={plusBask}>
                                                {textTranslate.to_basket}
                                            </Button>
                                        :
                                        <Stack
                                            direction="row"
                                            spacing={0}
                                            sx={{
                                                width: orient == 'horizontal' ? "auto" : "100%",
                                                justifyContent: { xs: 'center', sm: 'flex-end' },
                                                alignItems: "stretch",
                                            }}
                                        >
                                            <IconButton variant="soft" color="primary" size="sm" onClick={minusBask} sx={{ borderEndEndRadius: '0', borderTopRightRadius: '0', width: orient == 'horizontal' ? "auto" : "100%" }}>
                                                <RemoveRoundedIcon />
                                            </IconButton>
                                            <Sheet variant="soft" color="primary" size="sm"
                                                sx={(theme) => ({
                                                    py: 0,
                                                    fontSize: 'md',
                                                    px: 1.5,
                                                    textAlign: "center",
                                                    height: 'auto',
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: orient == 'horizontal' ? "auto" : "100%",
                                                    // backgroundColor: "primary.800",
                                                    borderRadius: '0',
                                                    [theme.breakpoints.down('sm')]: {
                                                        // width: 'auto'
                                                    }
                                                })}
                                            >
                                                {count}
                                            </Sheet>
                                            <IconButton variant="soft" color="primary" size="sm" onClick={plusBask} sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', width: orient == 'horizontal' ? "auto" : "100%" }}>
                                                <AddRoundedIcon />
                                            </IconButton>
                                        </Stack>
                                    }
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}