import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider, CircularProgress } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AddBoxRounded } from '@mui/icons-material';
import { textStock } from '../../texts';


export default function ModalLoad() {
    const { loadLang, textTranslate } = useContext(LayoutContext);
    
    useEffect(() => {
        if (loadLang) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        // return () => document.body.style.overflow = 'auto';
    }, [loadLang]);
    
    return (
        <AnimatePresence>
            {loadLang && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(3px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            zIndex: 9999,
                        }}
                    />
                    <motion.div
                        initial={{ opacity: 0  }} // Начальное положение и прозрачность
                        animate={{ opacity: 1 }} // Анимация открытия
                        exit={{ opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)', // Центрируем элемент
                            right: 0,
                            height: 'fit-content',
                            width: 'fit-content',
                            zIndex: 9999,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            {/* <CircularProgress size="md" variant="plain" color="success" /> */}
                            <img src='/duck.gif' height={200} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                            <Typography sx={{ backgroundColor: 'neutral.50', p: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                                {/* {textStock.lang_load} */}
                                {textTranslate.loading}...
                            </Typography>
                        </Stack>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}