import React, { createContext, useEffect, useState } from 'react';
import texts from './texts';
import { translateText, translateTextObject } from './utils';
import { APICreateOrder, APIGetUser, APIUpdateUser } from './api';
import { useTheme } from '@mui/joy';


// Создаем контекст с начальным значением
export const LayoutContext = createContext();



// Функция для получения языка из localStorage
const getInitialLanguage = () => {
  const savedLanguage = localStorage.getItem('language');
  console.log("FD", savedLanguage);

  if (savedLanguage != 'undefined' && savedLanguage != 'null' && savedLanguage) {
    return savedLanguage;
  } else {
    const defaultLanguage = 'en'; // Язык по умолчанию
    localStorage.setItem('language', defaultLanguage); // Сохраняем язык по умолчанию
    console.log("FD1", localStorage.getItem('language'));
    return defaultLanguage;
  }
};


// Создаем провайдер, который будет оборачивать все компоненты
export const LayoutProvider = ({ children }) => {
    // const [ theme, setTheme ] = useState('light');

    const [ loadLang, setLoadLang ] = useState(false);
    const [ language, setLanguage ] = useState(getInitialLanguage());
    const [ textTranslate, setTextTranslate ] = useState(texts);

    const [ user, setUser ] = useState({});
    const [ modalProductOpen, setModalProductOpen ] = useState(false);
    const [ modalProduct, setModalProduct ] = useState({});

    const [ modalAccountOpen, setModalAccountOpen ] = useState(false);
    
    const [ modalBasketOpen, setModalBasketOpen ] = useState(false);
    const [ basket, setBasket ] = useState([]);

    const [ modalOrderCreate, setModalOrderCreate ] = useState(false);
    
    const [ modalCompany, setModalCompany ] = useState({});
    const [ modalCompanyOpen, setModalCompanyOpen ] = useState(false);

    const [ modalOrders, setModalOrders ] = useState(false);

    const [ modalBannerInfo, setModalBannerInfo ] = useState({ open: false, info: undefined });

    const [ webApp, setWebApp ] = useState(window?.Telegram?.WebApp);


    // -=-=- Загрузка WebApp -=-=-
    useEffect(() => {
      // Проверяем наличие значения в localStorage на стороне клиента
      // const savedLanguage = localStorage.getItem('language');
      // if (savedLanguage) setLanguage(savedLanguage);
      // else {
      //     // Если языка нет в localStorage, сохраняем язык по умолчанию
      //     localStorage.setItem('language', 'en');
      //     setLanguage('en');
      // }

      console.log(textTranslate);
      setWebApp(window.Telegram.WebApp);
      // Сообщаем Telegram, что WebApp готово
      webApp?.ready();
      // Разворачиваем WebApp на весь экран
      webApp?.expand();
      // отключить вертикальный свайп
      webApp?.disableVerticalSwipes();
    }, []);
    // -=-=-=-=-=-=-=-=-=-=-=-

    // Функция для переключения темы
    // const toggleTheme = () => {
    //   setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    // };


    // Загрузка пользователя
    useEffect(() => {
      if (!user?.id && user?.token) {
        APIGetUser(user?.token).then((data) => {
          if (data) {
            setUser({...user, ...data});
            if (data?.is_block) {
              webApp?.showAlert(textTranslate.block_account);
            }
            console.log("ЗАГРУЗКА Пользователя", data?.lang);
            // Сохранить в localStorage
            localStorage.setItem('language', data?.lang);
            setLanguage(data?.lang);
          }
        });
      }
    }, [user]);

    // useEffect(() => {
    //   var _lang = localStorage?.getItem('language');
    //   if (_lang && !user?.lang) setLanguage(_lang);
    // }, []);
    



    // Добавить элмент в корзину
    const addBasket = (count, setCount, product) => {
      // -=-=- Проверяем чтобы в корзине не было больше 2 магазинов -=-=-
      const shopId = product?.shop?.id;
      const shopCount = new Set(basket.map(item => item?.product?.shop?.id)).size;

      // Проверяем, не превышает ли количество магазинов лимит (2)
      if (shopCount >= 2 && !basket.some(item => item?.product?.shop?.id === shopId)) {
        webApp?.showAlert(textTranslate?.cannot_add_products);
        return;
      }
      // -=-=-=-=-=-=-=-=-=-=-=-=-=-

      var basks = basket;
      const bask_id = basket.findIndex((element) => element.product.id === product.id)
      if (bask_id > -1) {
          basks[bask_id].count = count;
          setBasket([...basks]);
      }
      else {
          const newBask = {product: product, count: 1, setCount: setCount};
          setBasket([...basket, newBask]);
      }

      return count;
    }

    // Уменьшить элемент в корзине
    const removeBasket = (count, product) => {
        var basks = basket;
        
        const bask_id = basket.findIndex((element) => element.product.id === product.id)
        if (bask_id > -1)
            if (count < 1) basks = basks.filter(p => p.product.id !== basks[bask_id].product.id);
            else basks[bask_id].count = count;
            setBasket([...basks]);

        return count;
    }



    useEffect(() => {
      if (modalBasketOpen || modalOrderCreate) {
        webApp?.MainButton?.hide();
      }
      else if (basket?.length < 1) {
        webApp?.MainButton?.hide();
      }
      else {
        // Настраиваем главную кнопку
        // webApp?.MainButton?.color(theme.colorSchemes.light.palette);  // Устанавливаем текст кнопки
        webApp?.MainButton?.setText(textTranslate?.basket);  // Устанавливаем текст кнопки
        webApp?.MainButton?.show();  // Отображаем кнопку
        
        // Делаем кнопку активной
        webApp?.MainButton?.enable();

        // Обработка нажатия на главную кнопку
        webApp?.onEvent('mainButtonClicked', function() {
          setModalBasketOpen(true);
        });
      }
    }, [basket, modalBasketOpen, modalOrderCreate, webApp])

    // Отслеживаем изменение текстов
    // useEffect(() => {
    //   localStorage.setItem('language', language);
    // }, [textTranslate]);

    // Если происходит смена языка
    useEffect(() => {
      const loadTranslations = async () => {
        console.log('aaaaa');
        console.log('Lang', localStorage.getItem('language'));
        console.log('Lang2', language);
        console.log(localStorage.getItem('language') == language);
        
        if (localStorage.getItem('language'))
          localStorage.setItem('language', language);

        setTextTranslate(texts[language]);
        // Настраиваем главную кнопку
        webApp?.MainButton?.setText(texts[language].basket);
        
        if (user?.token) {
          const _data = {
            "token": user?.token,
            "lang": language
          }
          APIUpdateUser(_data).then((data) => {
            if (data) {
                setUser({...user, ...data});
            }
          });
        }
      };
      loadTranslations();
    }, [language]);


    const _values = {
        // theme, toggleTheme,
        user, setUser,
        addBasket, removeBasket,

        modalProductOpen, setModalProductOpen,
        modalProduct, setModalProduct,
        modalAccountOpen, setModalAccountOpen,
        modalBasketOpen, setModalBasketOpen,
        basket, setBasket,
        modalOrderCreate, setModalOrderCreate,
        
        modalCompany, setModalCompany,
        modalCompanyOpen, setModalCompanyOpen,

        modalOrders, setModalOrders,
        modalBannerInfo, setModalBannerInfo,

        webApp, setWebApp,

        loadLang, setLoadLang,
        language, setLanguage,
        textTranslate, setTextTranslate,
    }
  
    return (
      <LayoutContext.Provider value={_values}>
        {children}
      </LayoutContext.Provider>
    );
};