import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { Card, CardContent, CardCover, Chip, Divider, Typography } from '@mui/joy';
import ItemProduct from '../ItemProduct/ItemProduct';


export default function SliderProducts({ products }) {
    return (
        <Swiper
            grabCursor={true}
            spaceBetween={10}
            centeredSlides={true}
            centeredSlidesBounds={true}
            slidesPerView={ products?.length >= 3 ? 2.05 : 2}
            autoplay={{
                delay: 8000
            }}
            modules={[ Autoplay ]}
            className="SwiperItem"
            style={{ padding: '10px 15px', width: 'auto' }}
        >
            {
                products?.map((product) => ( 
                    <SwiperSlide style={{ height: 'auto' }} key={product.id}>
                        <ItemProduct orientation={"vertical"} item={product} />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    )
}