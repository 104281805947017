import { Box, Button, DialogContent, IconButton, Link, Modal, ModalClose, ModalDialog, ModalOverflow, Sheet, Skeleton, Stack, Typography, useTheme } from "@mui/joy";
import React, { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

import { LayoutContext } from "../../context";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import SliderDemonstProduct from "./SliderDemonstProduct";
import { APIGetShopInfo } from "../../api";


function ModalProduct ({ }) {
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);
    const { modalProductOpen, setModalProductOpen, setModalCompanyOpen, setModalCompany } = useContext(LayoutContext);
    const { modalProduct, setModalProduct, textTranslate, language } = useContext(LayoutContext);

    // Перейти на страницу компании
    function toCompany() {
        APIGetShopInfo(modalProduct?.product?.shop?.id, true, language).then((data) => {
            if (data) {
                setModalProductOpen(false);
                console.log('SHOP', data)
                setModalCompany(data);
                setModalCompanyOpen(true);
            }
        });
    }
 
    // Уменьшить товар в корзине
    function minusBask() {
        removeBasket(modalProduct?.count - 1, modalProduct?.product);
        // console.log(basket);
        setModalProduct({ ...modalProduct, count: modalProduct?.count - 1 });
    }
    // Увеличить товар в корзине
    function plusBask() {
        addBasket(modalProduct?.count + 1, modalProduct?.setCount, modalProduct?.product);
        // console.log(basket);
        setModalProduct({ ...modalProduct, count: modalProduct?.count + 1 });
    }

    return (
        <AnimatePresence>
            {modalProductOpen && (
                <Modal
                    open={modalProductOpen}
                    onClose={() => setModalProductOpen(false)}
                    slotProps={{
                        backdrop: {
                            component: motion.div,
                            initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                            animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                            exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                            transition: { duration: 0.3 },
                        },
                    }}
                    sx={{
                        zIndex: 12,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        visibility: modalProductOpen ? 'visible' : 'hidden',
                    }}
                >
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            color="primary"
                            variant="plain"
                            component={motion.div}
                            initial={{ opacity: 0, scale: 0.8, transform: 'translate(0, -5%)' }}
                            animate={{ opacity: 1, scale: 1, transform: 'translate(0, 0%)' }}
                            exit={{ opacity: 0, scale: 0.8, transform: 'translate(0, -5%)' }}
                            transition={{ duration: 0.3 }}
                            sx={(theme) => ({
                                width: 600,
                                pt: 6,
                                [theme.breakpoints.down('sm')]: {
                                    px: 1,
                                    pb: 1,
                                },
                                borderRadius: 'xl',
                                zIndex: 13,
                            })}
                        >
                            <ModalClose color="danger" />
                            <DialogContent>
                                <Stack
                                    direction={'column'}
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ height: '100%' }}
                                >
                                    <SliderDemonstProduct photos={modalProduct?.product?.photos_product} />
                                    {/* <AspectRatio ratio="4/4" sx={{ width: '100%', borderRadius: 'xl',
                                        '& > .MuiAspectRatio-content': { 
                                            ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' }
                                        )}
                                    }}>
                                        { image == '' ?
                                        <Box />
                                        :
                                        <Skeleton loading={false} variant="overlay">
                                            <img style={{ borderRadius: 'xl' }} 
                                            width={"100%"} 
                                            src={ image == undefined ? '/default.png' : image }
                                            loading="lazy" alt=""/>
                                        </Skeleton> 
                                    }
                                    </AspectRatio> */}
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ px: 1 }}>
                                            <Box sx={{ mb: 1 }}>
                                                <Typography 
                                                    sx={(theme) => ({
                                                        [theme.breakpoints.down('small')]: {
                                                            fontSize: theme.fontSize.xl2
                                                        },
                                                        lineHeight: 1,
                                                        "& p": {
                                                            m: 0
                                                        }
                                                    })} 
                                                    fontWeight={'bold'} 
                                                    level="h4"
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: modalProduct?.product?.name }} />
                                                </Typography>
                                                { language != 'en' && modalProduct?.product?.translate_title && (
                                                    <Typography 
                                                        sx={(theme) => ({
                                                            // [theme.breakpoints.down('small')]: {
                                                            //     fontSize: theme.fontSize.xl2
                                                            // },
                                                            lineHeight: 1,
                                                            color: "neutral.500",
                                                            "& p": {
                                                                m: 0
                                                            }
                                                        })} 
                                                        // fontWeight={'bold'} 
                                                        level="lg"
                                                        >
                                                            <div dangerouslySetInnerHTML={{ __html: modalProduct?.product?.name_en }} />
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Link endDecorator={<LaunchRoundedIcon fontSize='lg' sx={{ color: 'primary.500' }}/>} underline="always" color="primary" level="body-md" sx={{ mb: 2 }} onClick={toCompany}>
                                                {modalProduct?.product?.shop?.store_name}
                                            </Link>
                                            <Typography
                                                level="body-sm"
                                                lineHeight={1.2}
                                                sx={{
                                                    "& p": {
                                                        m: 0
                                                    }
                                                }}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: modalProduct?.product?.description }} />
                                            </Typography>
                                            <Box sx={{ mt: 2 }}>
                                                <Stack 
                                                    sx={{ width: '100%' }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >
                                                    <Stack
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="flex-start"
                                                        spacing={-0.8}
                                                    >
                                                        <Typography>
                                                            {textTranslate.price}:
                                                        </Typography>
                                                        <Typography sx={(theme) => ({
                                                            px: 0, fontWeight: 'xl', color: theme.vars.palette.primary[700],
                                                            [theme.breakpoints.down('small')]: {
                                                                fontSize: theme.fontSize.xl2
                                                            }
                                                            })} level="h2">
                                                            {Math.ceil(modalProduct?.product?.price)} Rs
                                                        </Typography>
                                                    </Stack>
                                                        
                                                    <Box 
                                                        sx={{ 
                                                            // width: "100%",
                                                            opacity: modalProduct?.product?.shop?.status_work ? 1 : 0.5, // Бледный, если магазин закрыт
                                                            pointerEvents: modalProduct?.product?.shop?.status_work ? "auto" : "none", // Отключает клики, если магазин закрыт
                                                        }}
                                                    >
                                                        {
                                                            modalProduct?.count <= 0 ?
                                                                <Button 
                                                                    size="md"
                                                                    variant="soft"
                                                                    color={modalProduct?.product?.shop?.status_work ? "primary" : "danger"}
                                                                    sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: "auto", lineHeight: 1 }}
                                                                    onClick={plusBask}
                                                                >
                                                                    {textTranslate.to_basket}
                                                                </Button>
                                                            :
                                                            <Stack
                                                                direction="row"
                                                                spacing={0}
                                                                sx={{
                                                                    width: "auto",
                                                                    justifyContent: { xs: 'center', sm: 'flex-end' },
                                                                    alignItems: "stretch",
                                                                }}
                                                            >
                                                                <IconButton variant="soft" color="primary" size="md" onClick={minusBask} sx={{ borderEndEndRadius: '0', borderTopRightRadius: '0', width: "auto" }}>
                                                                    <RemoveRoundedIcon />
                                                                </IconButton>
                                                                <Sheet variant="soft" color="primary" size="md"
                                                                    sx={(theme) => ({
                                                                        py: 0,
                                                                        fontSize: 'md',
                                                                        px: 1.5,
                                                                        textAlign: "center",
                                                                        height: 'auto',
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        width: "auto",
                                                                        // backgroundColor: "primary.800",
                                                                        borderRadius: '0',
                                                                        [theme.breakpoints.down('sm')]: {
                                                                            // width: 'auto'
                                                                        }
                                                                    })}
                                                                >
                                                                    {modalProduct?.count}
                                                                </Sheet>
                                                                <IconButton variant="soft" color="primary" size="md" onClick={plusBask} sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', width: "auto" }}>
                                                                    <AddRoundedIcon />
                                                                </IconButton>
                                                            </Stack>
                                                        }
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Stack>
                            </DialogContent>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </AnimatePresence>
    )
}

export default ModalProduct;