import { Box, Button, Container, Grid, Link, Stack, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../context";
import { useContext, useEffect, useMemo, useState } from "react";
import { APIGetProductsCategory, APIGetProductsGroup, APISearch, APITypeGroupsByCategory } from "../api";


export default function PersonalDataPage() {
    const { user, textTranslate, language } = useContext(LayoutContext);
    
    // const { urlDoc } = useParams(); // захватываем раздел из URL
    const [ searchParams, setSearchParams ] = useSearchParams();
    const urlDoc = searchParams.get('urlDoc');


    // Компонент для обработки кликов по карте
    function LegrD() {
        return (
            <iframe 
                src={urlDoc} 
                width="100%" 
                height="100%" 
                style={{ border: "none", minHeight: "60vh" }}>
            </iframe>
        )
    };


    return (
        <Container maxWidth="sm" sx={{ my: 1, mb: 4 }}>
            <Typography level="h2" mb={2}>
                {textTranslate.personal_data}
            </Typography>
            <LegrD/>
        </Container>
    )
}