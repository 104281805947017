

import { CircularProgress, Stack, Typography } from "@mui/joy";
import classes from "./LoaderPage.module.css";


export default function LoaderPage() {
    return (
        <div className={classes.loader_page}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                style={{ width: '100%', height: '100%' }}
            >
                <img width={120} src="/duck.gif" />
                <CircularProgress size="md" />
            </Stack>
        </div>
    );
}