import { Box, DialogContent, Modal, ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import React, { useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { LayoutContext } from "../../context";


export default function ModalBannerInfo() {
    const { modalBannerInfo, setModalBannerInfo } = useContext(LayoutContext);

    return (
        <AnimatePresence>
            {modalBannerInfo?.open && (
                <Modal
                    open={modalBannerInfo?.open}
                    onClose={() => setModalBannerInfo({ ...modalBannerInfo, open: false })}
                    slotProps={{
                        backdrop: {
                            component: motion.div,
                            initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                            animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                            exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                            transition: { duration: 0.3 },
                        },
                    }}
                    sx={{
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        visibility: modalBannerInfo?.open ? 'visible' : 'hidden',
                    }}
                >
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            color="primary"
                            variant="plain"
                            component={motion.div}
                            initial={{ opacity: 0, scale: 0.8, transform: 'translate(0, -3%)' }}
                            animate={{ opacity: 1, scale: 1, transform: 'translate(0, 0%)' }}
                            exit={{ opacity: 0, scale: 0.8, transform: 'translate(0, -3%)' }}
                            transition={{ duration: 0.3 }}
                            sx={(theme) => ({
                                width: 600,
                                pt: 6,
                                [theme.breakpoints.down('sm')]: {
                                    px: 2,
                                    pb: 1,
                                },
                                borderRadius: 'xl',
                                zIndex: 2,
                            })}
                        >
                            <ModalClose />
                            <DialogContent>
                                <Stack
                                    direction={'column'}
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    sx={{ height: '100%' }}
                                >
                                    {/* <AspectRatio ratio="4/4" sx={{ width: '100%', borderRadius: 'xl',
                                        '& > .MuiAspectRatio-content': { 
                                            ...( image === '' && { display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0, height: '177px' }
                                        )}
                                    }}>
                                        { image == '' ?
                                        <Box />
                                        :
                                        <Skeleton loading={false} variant="overlay">
                                            <img style={{ borderRadius: 'xl' }} 
                                            width={"100%"} 
                                            src={ image == undefined ? '/default.png' : image }
                                            loading="lazy" alt=""/>
                                        </Skeleton> 
                                    }
                                    </AspectRatio> */}
                                    <Box sx={{ width: '100%', position: 'relative' }}>
                                        <div dangerouslySetInnerHTML={{ __html: modalBannerInfo?.info }} />
                                    </Box>
                                </Stack>
                            </DialogContent>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </AnimatePresence>
    )
}